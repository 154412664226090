/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Apps, Message, People, FavoriteBorder, DonutSmall} from "@material-ui/icons";

// React icons
import {FaTwitter, FaFacebook, FaInstagram} from 'react-icons/fa';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({...props}) {
    const {classes} = props;
    return (
        <List className={classes.list}>
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <CustomDropdown*/}
            {/*        noLiPadding*/}
            {/*        buttonText="Creative"*/}
            {/*        buttonProps={{*/}
            {/*            className: classes.navLink,*/}
            {/*            color: "transparent"*/}
            {/*        }}*/}
            {/*        // buttonIcon={FavoriteBorder}*/}
            {/*        dropdownList={[*/}
            {/*            <Link to="/creative" className={classes.dropdownLink}>*/}
            {/*                What we do*/}
            {/*            </Link>,*/}
            {/*            <Link to="/creative/portfolio" className={classes.dropdownLink}>*/}
            {/*                Portfolio*/}
            {/*            </Link>*/}
            {/*        ]}*/}
            {/*    />*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Button*/}
            {/*        // href="/creative"*/}
            {/*        component={Link}*/}
            {/*        to="/creative"*/}
            {/*        color="primary"*/}
            {/*        className={classes.navLink}*/}
            {/*        style={{marginRight: "0.5em"}}*/}
            {/*    >*/}
            {/*        <FavoriteBorder className={classes.icons}/> Creative*/}
            {/*    </Button>*/}
            {/*</ListItem>*/}
            <ListItem className={classes.listItem}>
                <Button
                    component={Link}
                    to="/digital"
                    color="transparent"
                    className={classes.navLink}
                    style={{marginRight: "0.5em"}}
                >
                    {/*<DonutSmall className={classes.icons}/> */}
                    Nari Solutions
                </Button>
            </ListItem>
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Button*/}
            {/*        component={Link}*/}
            {/*        to="/team"*/}
            {/*        color="transparent"*/}
            {/*        className={classes.navLink}*/}
            {/*    >*/}
            {/*        /!*<People className={classes.icons}/> *!/*/}
            {/*        Team*/}
            {/*    </Button>*/}
            {/*</ListItem>*/}
            <ListItem className={classes.listItem}>
                <Button
                    component={Link}
                    to="/career"
                    color="transparent"
                    className={classes.navLink}
                >
                    Career
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    component={Link}
                    to="/contact"
                    color="transparent"
                    className={classes.navLink}
                >
                    {/*<Message className={classes.icons}/> */}
                    Contact us
                </Button>
            </ListItem>
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Tooltip*/}
            {/*        id="instagram-twitter"*/}
            {/*        title="Follow us on twitter"*/}
            {/*        placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}*/}
            {/*        classes={{tooltip: classes.tooltip}}*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            href="https://twitter.com/narisolutions"*/}
            {/*            target="_blank"*/}
            {/*            color="transparent"*/}
            {/*            className={classes.navLink}*/}
            {/*        >*/}
            {/*            <FaTwitter/>*/}
            {/*        </Button>*/}
            {/*    </Tooltip>*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Tooltip*/}
            {/*        id="instagram-facebook"*/}
            {/*        title="Follow us on facebook"*/}
            {/*        placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}*/}
            {/*        classes={{tooltip: classes.tooltip}}*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            color="transparent"*/}
            {/*            href="https://www.facebook.com/narisolutions"*/}
            {/*            target="_blank"*/}
            {/*            className={classes.navLink}*/}
            {/*        >*/}
            {/*            <FaFacebook/>*/}
            {/*        </Button>*/}
            {/*    </Tooltip>*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.listItem}>*/}
            {/*    <Tooltip*/}
            {/*        id="instagram-tooltip"*/}
            {/*        title="Follow us on instagram"*/}
            {/*        placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}*/}
            {/*        classes={{tooltip: classes.tooltip}}*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            color="transparent"*/}
            {/*            href="https://www.instagram.com/narisolutions"*/}
            {/*            target="_blank"*/}
            {/*            className={classes.navLink}*/}
            {/*        >*/}
            {/*            <FaInstagram/>*/}
            {/*        </Button>*/}
            {/*    </Tooltip>*/}
            {/*</ListItem>*/}
        </List>
    );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
