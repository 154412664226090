/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes

import classNames from "classnames";
import {List, ListItem, Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";


// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";

import Button from "components/CustomButtons/Button.jsx";
import {Link} from "gatsby";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/"
                className={classes.block}
              >
                Nari
              </Link>
            </ListItem>
              <ListItem className={classes.inlineBlock}>
              <Link
                to="/team"
                className={classes.block}
              >
                Team
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/career"
                className={classes.block}
              >
                Career
              </Link>
            </ListItem>
            {/*<ListItem className={classes.inlineBlock}>*/}
            {/*  <a*/}
            {/*    href="http://blog.nari.ge/"*/}
            {/*    className={classes.block}*/}
            {/*    target="_blank"*/}
            {/*  >*/}
            {/*    Blog*/}
            {/*  </a>*/}
            {/*</ListItem>*/}
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/contact"
                className={classes.block}
              >
                Contact us
              </Link>
            </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Tooltip
                    id="linkedin"
                    title="Follow us on LinkedIn"
                    placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        href="https://www.linkedin.com/company/narisolutions"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <FaLinkedin/>
                    </Button>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
                <Tooltip
                    id="instagram-twitter"
                    title="Follow us on twitter"
                    placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        href="https://twitter.com/narisolutions"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <FaTwitter/>
                    </Button>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
                <Tooltip
                    id="instagram-facebook"
                    title="Follow us on facebook"
                    placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://www.facebook.com/narisolutions"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <FaFacebook/>
                    </Button>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
                <Tooltip
                    id="instagram-tooltip"
                    title="Follow us on instagram"
                    placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://www.instagram.com/narisolutions"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <FaInstagram/>
                    </Button>
                </Tooltip>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
            <Typography>
           <Link
            to="/"
            className={aClasses}
            target="_blank"
          >
            Nari Solutions LLC
          </Link>{" "}
          &copy; {1900 + new Date().getYear()}
          </Typography>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
